<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="2" class="my-1">

    </b-col>
    <b-col md="4" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>


    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="2">
              <small class="text-warning">Tahun Anggaran</small>
              <h1 class="text-warning"><strong style="font-size :45px">{{ data.item.th }}</strong></h1>
              <h6 class="text-dark"> <small> Keterangan </small><br> <small style="text-align: justify;">{{
                data.item.status }}</small></h6>

            </b-col>
            <b-col md="10">
              <!-- <h6 v-for="ruc in data.item.ruc" v-bind:key="ruc.id_ruc"><small><strong>- {{ kab.nm_kab }}</strong></small>
              </h6> -->
              <b-row>
                <b-col md="6" v-for="ruc in data.item.ruc" v-bind:key=ruc.id_ruc>
                  <b-alert variant="warning" show>
                    <div class="alert-body">
                      <b-row>
                        <b-col md="10">
                          <h6 class="text-primary"><strong>{{ ruc.nama_ruc }} </strong><br>
                            <small>{{ ruc.keterangan }}</small><br>

                            <strong style="font-size:25px"><sup>Rp. </sup>{{ Rp(ruc.nominal_ruc) }}</strong>
                          </h6>
                        </b-col>

                        <b-col md="2" v-if="data.item.status == 'Penyusunan Anggaran'">
                          <b-button variant="success" block size="sm" v-if="ruc.nominal_ruc == null"
                            @click="SetRuc(ruc, data.item.th)" v-b-modal.modal-1>
                            <feather-icon icon="PlusCircleIcon" size='15' />
                          </b-button>
                          <b-button variant="warning" block size="sm" v-if="ruc.nominal_ruc != null"
                            @click="SetRuc(ruc, data.item.th)" v-b-modal.modal-1>
                            <feather-icon icon="EditIcon" size='15' />
                          </b-button>
                        </b-col>
                      </b-row>

                    </div>
                  </b-alert>


                </b-col>
              </b-row>


            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      Total Tahun Anggaran {{ items.length }}
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert
  },
  props: {
    rsData: {}
  },
  data() {
    return {
      perPage: 1,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],


    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsData;
      },
      set: function (newValue) {
        return this.rsData = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ["SetRuc"],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    SetRuc(val1, val2) {
      this.$emit("SetRuc", val1, val2)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>