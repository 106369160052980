<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel-ruc :rsData=rsData @SetRuc=SetRuc></tabel-ruc>
        </b-card>
      </b-col>
    </b-row>
    <set-ruc :trRUC=trRUC :th=th @SimpanRUC=SimpanRUC></set-ruc>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import TabelRuc from './component/tabelRuc.vue'
import SetRuc from './component/setRuc.vue'
export default {
  components: {
    BTabs,
    BTab, TabelRuc, BRow, BCol, BCard,
    SetRuc
  },
  data() {
    return {
      rsData: [],
      trRUC: {},
      th: "",
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();

  },
  methods: {
    async SimpanRUC(input, trruc, th) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruc/act_ruc',
        data: {
          tahun: th,
          id_ruc: trruc.id_ruc,
          nominal_ruc: input
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    SetRuc(val, val2) {
      this.trRUC = val
      this.th = val2
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruc/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>