<template>
  <div>
    <b-modal id="modal-1" :title="'Set Besaran RUC ' + trRUC.keterangan" ok-disabled no-close-on-backdrop hide-footer>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!--  This field is required-->
            <b-col md="12">
              <b-form-group :label="trRUC.keterangan + ' - ' + trRUC.nama_ruc" label-for="username">
                <validation-provider #default="{ errors }" rules="required" name="Input Nominal RUC">
                  <CurrencyInput v-model="inputs" :state="errors.length > 0 ? false : null" placeholder="Rp."
                    class="form-control" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button variant="primary" type="submit" @click.prevent="validationForm" class="mr-1" size="sm">
                <feather-icon icon="SaveIcon" class="mr-50" size='15' /> Simpan
              </b-button>
              <b-button variant="warning" size="sm" @click="close()">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Batal
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import CurrencyInput from './CurrencyInput.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  required
} from '@validations'


import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormInput, BFormGroup, BForm, BRow, BCol, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, CurrencyInput,
    BModal,
    BAlert, BFormInput, BFormGroup, BForm, BRow, BCol, ValidationProvider, ValidationObserver
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      inputs: ""
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["SimpanRUC"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit("SimpanRUC", this.inputs, this.trRUC, this.th);
          this.inputs = "";
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    trRUC: {},
    th: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>